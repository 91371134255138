import { configureStore } from '@reduxjs/toolkit'
import commonToolsReducer from './slices/commonTools'
import collectToolsReducer from './slices/collectTools'

export const store = configureStore({
    reducer: {
        commonTools: commonToolsReducer,
        collectTools: collectToolsReducer,
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch