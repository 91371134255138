import { ReactNode } from 'react';
import { Link } from "react-router-dom";

enum ActionType {
    Added = 'blue', // 新增
    Modified = 'green', // 修改
    Removed = 'red', // 移除
}

interface NoticeItem {
    action: ActionType
    content: string | ReactNode
    date: string
}

const items: NoticeItem[] = [
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/QkeW5NJt94">文本去重复</Link>工具</>,
        date: '2022-05-18',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/NznOHs419G">多功能文本去重</Link>工具</>,
        date: '2022-05-18',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/WhX2xWx0Pr">文本行批量删除N个字符</Link>工具</>,
        date: '2022-05-19',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/TNo9JCtpuy">文本行批量插入字符串</Link>工具</>,
        date: '2022-05-19',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/FVmHTbUrzL">MD5加密</Link>工具</>,
        date: '2022-05-21',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/RAV8WUXLE4">SHA加密</Link>工具</>,
        date: '2022-05-21',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/E54UyZIdbe">AES加密</Link>工具</>,
        date: '2022-05-21',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/T3VwLebNFv">AES解密</Link>工具</>,
        date: '2022-05-21',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/GNvUgiUaEb">DES加密</Link>工具</>,
        date: '2022-05-21',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/QyNIFVh5AD">DES解密</Link>工具</>,
        date: '2022-05-21',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/TzwLcBPMju">随机字符串</Link>工具</>,
        date: '2022-05-23',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/ALckybAgOT">UUID</Link>工具</>,
        date: '2022-05-23',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/AJOBtWw6HC">文本行固定长度填充</Link>工具</>,
        date: '2022-05-24',
    },
    {
        action: ActionType.Modified,
        content: <>修改<Link to="/t/WhX2xWx0Pr">文本行批量删除N个字符</Link>工具, 增加自定义删除位置</>,
        date: '2022-05-24',
    },
    {
        action: ActionType.Modified,
        content: <>修改<Link to="/t/TNo9JCtpuy">文本行批量插入字符串</Link>工具, 增加自定义插入位置</>,
        date: '2022-05-24',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/JDiadXgiFX">文本行批量克隆复制</Link>工具</>,
        date: '2022-05-24',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/Vi4og4ak30">文本行批量截取</Link>工具</>,
        date: '2022-05-24',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/VAt9az4uX7">文本列表排序</Link>工具</>,
        date: '2022-05-25',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/SLlD7ZhH2a">文本列表反转排序</Link>工具</>,
        date: '2022-05-25',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/M57m7VAZNN">汉字排序</Link>工具</>,
        date: '2022-05-25',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/ELGCyD1ITk">文本行字符串左右反转</Link>工具</>,
        date: '2022-05-25',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/Z34T6ONYAK">文本行合并</Link>工具</>,
        date: '2022-05-25',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/Yzlo6UriFz">文本列合并</Link>工具</>,
        date: '2022-05-25',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/KEyJoopqOl">文本列取交集</Link>工具</>,
        date: '2022-05-25',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/MDhYqCqSWC">文本列取差集</Link>工具</>,
        date: '2022-05-25',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/W5AuPlAIqk">身份证号码生成器</Link>工具</>,
        date: '2022-05-26',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/IKwWqrV3sg">手机号码生成器</Link>工具</>,
        date: '2022-05-26',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/RqS2XScp8v">文本按列截取</Link>工具</>,
        date: '2022-05-27',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/X2PzyXs35H">随机IP地址</Link>工具</>,
        date: '2022-05-27',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/M28sMxj7zn">过滤文本行所有空行</Link>工具</>,
        date: '2022-05-30',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/C9dpQ3nrzy">过滤文本行所有换行</Link>工具</>,
        date: '2022-05-30',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/DLoOjYdYeg">删除文本中空格</Link>工具</>,
        date: '2022-05-30',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/QXAFNnjuL3">删除文本中数字</Link>工具</>,
        date: '2022-05-30',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/KexnW5g4Aq">删除文本中字母</Link>工具</>,
        date: '2022-05-30',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/NW9KEx21nO">删除文本中汉字</Link>工具</>,
        date: '2022-05-30',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/FcpFhEPPYk">文本行关键词批量删除</Link>工具</>,
        date: '2022-05-30',
    },
    {
        action: ActionType.Modified,
        content: <>修改<Link to="/t/W5AuPlAIqk">身份证号码生成器</Link>工具, 数据库迁移</>,
        date: '2022-05-31',
    },
    {
        action: ActionType.Modified,
        content: <>修改<Link to="/t/IKwWqrV3sg">手机号码生成器</Link>工具, 数据库迁移</>,
        date: '2022-05-31',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/Y9QjMkzFDt">手机号码归属地</Link>工具</>,
        date: '2022-06-01',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/XXF2WzP65Y">身份证号码解析</Link>工具</>,
        date: '2022-06-01',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/SoecB2XYuG">生成RSA密钥对</Link>工具</>,
        date: '2022-06-01',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/UdE42lJsPj">RSA加密</Link>工具</>,
        date: '2022-06-03',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/PPEhJ3sfUD">RSA解密</Link>工具</>,
        date: '2022-06-03',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/VVtQlfjWKR">正则表达式测试</Link>工具</>,
        date: '2022-06-06',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/OnAITQ9jUk">文字内容批量替换</Link>工具</>,
        date: '2022-06-06',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/CGLNvlnqxz">筛选包含关键字的文本行</Link>工具</>,
        date: '2022-06-06',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/EJuGbAskro">筛选不包含关键字的文本行</Link>工具</>,
        date: '2022-06-06',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/ZmncKoH1od">文本批量筛选奇数行</Link>工具</>,
        date: '2022-06-06',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/Lyac8yjJww">文本批量筛选偶数行</Link>工具</>,
        date: '2022-06-06',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/CcxtKSX5Es">文本匹配提取URL链接</Link>工具</>,
        date: '2022-06-07',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/SO9KhhS2Ch">文本匹配提取IP地址</Link>工具</>,
        date: '2022-06-07',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/XId1fq8fJX">文本匹配提取邮箱地址</Link>工具</>,
        date: '2022-06-07',
    },
    {
        action: ActionType.Added,
        content: <>新增<Link to="/t/FoFZesz5V4">文本匹配提取手机号码</Link>工具</>,
        date: '2022-06-07',
    },
]

export default items