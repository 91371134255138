import { useParams } from "react-router-dom";
import { Suspense, ReactNode } from "react";
import { Empty, Spin, BackTop } from 'antd';
import toolsData, { ToolItem } from "../../../data/tools"
import "./index.less"
import backTopImage from './../../../assets/images/backtop.png'

export default () => {
    // 获取路由参数
    const { toolId: currentToolId } = useParams();

    // 查询当前工具
    const getToolComponent = (tools: ToolItem[] = toolsData): ReactNode | undefined => {
        for (let i = 0; i < tools.length; i++) {
            if (tools[i].isMenu || tools[i].isGroup) {
                // 递归调用
                const component = getToolComponent(tools[i].children as ToolItem[])
                if(component) {
                    return component
                }
            } else if (tools[i].id === currentToolId) {
                return tools[i].component
            }
        }

        return undefined
    }

    return (
        <div className="tool">
            <Suspense fallback={<Spin tip="加载中..." />}>
                { getToolComponent() || <Empty description="不存在的工具" /> }
            </Suspense>

            {/* 回到顶部 */}
            <BackTop visibilityHeight={100}>
                <img src={backTopImage} alt="回到顶部" title="回到顶部" style={{"width":"50px"}} />
            </BackTop>
        </div>
    )
}
