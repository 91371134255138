import { Button, Tag, Empty, Card, Timeline, BackTop, Row, Col } from 'antd'
import { useNavigate } from "react-router-dom";
import { useAppSelector } from '../../../store/hook'
import './index.less'
import noticesData from '../../../data/notices'
import backTopImage from './../../../assets/images/backtop.png'

export default () => {
    const navigate = useNavigate();
    const commonTools = useAppSelector((state) => state.commonTools.value)
    const collectTools = useAppSelector((state) => state.collectTools.value)

    return (
        <div className='home'>
            <h1 className='title'>在线工具 <Button type="link" href="https://www.fm-code.com/" target="_blank">www.fm-code.com</Button></h1>

            <div className='tools-panel'>
                <Row gutter={30}>
                    <Col xs={24} sm={24} md={12} className='panel__left'>
                        <Card title="最近常用" type="inner" style={{ "height": "100%" }}>
                            {
                                commonTools.length > 0
                                    ?
                                    <div>
                                        {
                                            commonTools.map(tool =>
                                                <Tag
                                                    style={{ "cursor": "pointer", "margin": "5px" }}
                                                    color="#2db7f5"
                                                    key={tool.id}
                                                    onClick={() => { navigate('/t/' + tool.id) }}>{tool.name}</Tag>
                                            )
                                        }
                                    </div>
                                    :
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            }
                        </Card>
                    </Col>

                    <Col xs={24} sm={24} md={12} className='panel__right'>
                        <Card title="我的收藏" type="inner" style={{ "height": "100%" }}>
                            {
                                collectTools.length > 0
                                    ?
                                    <div>
                                        {
                                            collectTools.map(tool =>
                                                <Tag
                                                    style={{ "cursor": "pointer", "margin": "5px" }}
                                                    color="#87d068"
                                                    key={tool.id}
                                                    onClick={() => { navigate('/t/' + tool.id) }}>{tool.name}</Tag>
                                            )
                                        }
                                    </div>
                                    :
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            }
                        </Card>
                    </Col>
                </Row>
                {/* <div className='panel__left'>
                    <Card title="最近常用" type="inner" style={{ "height": "100%" }}>
                        {
                            commonTools.length > 0
                                ?
                                <div>
                                    {
                                        commonTools.map(tool =>
                                            <Tag
                                                style={{ "cursor": "pointer", "margin": "5px" }}
                                                color="#2db7f5"
                                                key={tool.id}
                                                onClick={() => { navigate('/t/' + tool.id) }}>{tool.name}</Tag>
                                        )
                                    }
                                </div>
                                :
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </Card>
                </div>

                <div className='panel__right'>
                    <Card title="我的收藏" type="inner" style={{ "height": "100%" }}>
                        {
                            collectTools.length > 0
                                ?
                                <div>
                                    {
                                        collectTools.map(tool =>
                                            <Tag
                                                style={{ "cursor": "pointer", "margin": "5px" }}
                                                color="#87d068"
                                                key={tool.id}
                                                onClick={() => { navigate('/t/' + tool.id) }}>{tool.name}</Tag>
                                        )
                                    }
                                </div>
                                :
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </Card>
                </div> */}
            </div>

            <div className='notice-panel'>
                <Card title="网站公告" type="inner">
                    {
                        noticesData.length > 0
                            ?
                            <Timeline reverse={true}>
                                {
                                    noticesData.map((notice, index) => {
                                        return (
                                            <Timeline.Item key={index} color={notice.action}>
                                                {notice.content}
                                                <label style={{ "color": "#336699", "marginLeft": "15px", "whiteSpace": "nowrap" }}>
                                                    <i className='fm-icon-time'></i> {notice.date}
                                                </label>
                                            </Timeline.Item>
                                        )
                                    })
                                }
                            </Timeline>
                            :
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} imageStyle={{ "height": "100px" }} />
                    }
                </Card>
            </div>

            {/* 回到顶部 */}
            <BackTop visibilityHeight={100}>
                <img src={backTopImage} alt="回到顶部" title="回到顶部" style={{ "width": "50px" }} />
            </BackTop>
        </div>
    )
}