import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _pullAllBy from 'lodash/pullAllBy';
import { getToolsCache, setToolsCache, ToolState } from './commonTools';

// 初始化
const initialState: { value: ToolState[] } = {
    value: getToolsCache('my-collect-tools'),
}

export const toolsSlice = createSlice({
    name: 'tools',
    initialState,
    reducers: {
        addCollectTool: (state, action: PayloadAction<ToolState>) => {
            const tool = action.payload
            _pullAllBy(state.value, [{ id: tool.id }], 'id')
            // 在数组头部插入
            state.value.unshift(tool);
            setToolsCache('my-collect-tools', state.value)
        },
        removeCollectTool: (state, action: PayloadAction<string>) => {
            const toolId = action.payload
            _pullAllBy(state.value, [{ id: toolId }], 'id')
            setToolsCache('my-collect-tools', state.value)
        },
    },
})

export const { addCollectTool, removeCollectTool } = toolsSlice.actions

export default toolsSlice.reducer