import toolsData, { ToolItem } from "./tools"
import type { MenuProps } from 'antd';
import { MenuItemType } from "rc-menu/lib/interface";

type MenuItem = Required<MenuProps>['items'][number];

// 创建工具菜单
function createToolItems(tools: ToolItem[] = toolsData): MenuItem[] {
    let items = []
    for (let i = 0; i < tools.length; i++) {
        items.push(createToolItem(tools[i]))
    }
    return items
}

// 创建工具菜单项
function createToolItem(tool: ToolItem): MenuItem {
    if (tool.isMenu) { // 菜单
        return {
            key: tool.id,
            label: tool.name,
            children: createToolItems(tool.children as ToolItem[]),
            icon: tool.icon
        }
    } else if (tool.isGroup) { // 分组
        return {
            type: 'group',
            label: tool.name,
            children: createToolItems(tool.children  as ToolItem[]) as MenuItemType[],
        }
    } else {
        return {
            key: tool.id,
            label: tool.name,
            icon: tool.icon,
        }
    }
}

const items = [
    {
        label: "首页",
        key: 'home',
        icon: <i className="fm-icon-home"></i>
    },
    ...createToolItems()
]

export default items