import { useState, useEffect } from 'react';
import { Outlet, useNavigate, useParams } from "react-router-dom";
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import './Layout.less';
import navbarData from "../../data/navbar";

export default () => {
    const navigate = useNavigate();
    const { toolId } = useParams();

    // 当前路由参数改变时触发 Hook
    useEffect(() => {
        // 修改当前菜单选中项
        toolId && setCurrent(toolId)
    }, [toolId])

    // 当前菜单选中项
    const [current, setCurrent] = useState('home');

    // 点击菜单
    const handleMenuClick: MenuProps['onClick'] = e => {
        setCurrent(e.key)
        // 跳转页面
        switch (e.key) {
            case 'home':
                navigate('/')
                break;
            default:
                navigate('/t/' + e.key)
                break;
        }
    }

    return (
        <div className="layout">
            {/* 页面导航栏 */}
            <div className="page-navbar">
                <Menu onClick={handleMenuClick} selectedKeys={[current]} mode="horizontal" items={navbarData} />
            </div>

            {/* 页面内容 */}
            <div className="page-body">
                <Outlet />
            </div>
        </div>
    )
}