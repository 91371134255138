import React, { ReactNode } from "react";

// 懒加载组件
const NznOHs419G = React.lazy(() => import('../components/text/NznOHs419G'))
const QkeW5NJt94 = React.lazy(() => import('../components/text/QkeW5NJt94'))
const WhX2xWx0Pr = React.lazy(() => import('../components/text/WhX2xWx0Pr'))
const TNo9JCtpuy = React.lazy(() => import('../components/text/TNo9JCtpuy'))
const AJOBtWw6HC = React.lazy(() => import('../components/text/AJOBtWw6HC'))
const JDiadXgiFX = React.lazy(() => import('../components/text/JDiadXgiFX'))
const Vi4og4ak30 = React.lazy(() => import('../components/text/Vi4og4ak30'))
const VAt9az4uX7 = React.lazy(() => import('../components/text/VAt9az4uX7'))
const M57m7VAZNN = React.lazy(() => import('../components/text/M57m7VAZNN'))
const ELGCyD1ITk = React.lazy(() => import('../components/text/ELGCyD1ITk'))
const SLlD7ZhH2a = React.lazy(() => import('../components/text/SLlD7ZhH2a'))
const Z34T6ONYAK = React.lazy(() => import('../components/text/Z34T6ONYAK'))
const Yzlo6UriFz = React.lazy(() => import('../components/text/Yzlo6UriFz'))
const KEyJoopqOl = React.lazy(() => import('../components/text/KEyJoopqOl'))
const MDhYqCqSWC = React.lazy(() => import('../components/text/MDhYqCqSWC'))
const RqS2XScp8v = React.lazy(() => import('../components/text/RqS2XScp8v'))

const TzwLcBPMju = React.lazy(() => import('../components/random/TzwLcBPMju'))
const ALckybAgOT = React.lazy(() => import('../components/random/ALckybAgOT'))
const IKwWqrV3sg = React.lazy(() => import('../components/random/IKwWqrV3sg'))
const W5AuPlAIqk = React.lazy(() => import('../components/random/W5AuPlAIqk'))
const X2PzyXs35H = React.lazy(() => import('../components/random/X2PzyXs35H'))

const FVmHTbUrzL = React.lazy(() => import('../components/crypto/FVmHTbUrzL'))
const T3VwLebNFv = React.lazy(() => import('../components/crypto/T3VwLebNFv'))
const E54UyZIdbe = React.lazy(() => import('../components/crypto/E54UyZIdbe'))
const GNvUgiUaEb = React.lazy(() => import('../components/crypto/GNvUgiUaEb'))
const QyNIFVh5AD = React.lazy(() => import('../components/crypto/QyNIFVh5AD'))
const RAV8WUXLE4 = React.lazy(() => import('../components/crypto/RAV8WUXLE4'))
const UdE42lJsPj = React.lazy(() => import('../components/crypto/UdE42lJsPj'))
const SoecB2XYuG = React.lazy(() => import('../components/crypto/SoecB2XYuG'))
const PPEhJ3sfUD = React.lazy(() => import('../components/crypto/PPEhJ3sfUD'))

const M28sMxj7zn = React.lazy(() => import('../components/filter/M28sMxj7zn'))
const C9dpQ3nrzy = React.lazy(() => import('../components/filter/C9dpQ3nrzy'))
const DLoOjYdYeg = React.lazy(() => import('../components/filter/DLoOjYdYeg'))
const QXAFNnjuL3 = React.lazy(() => import('../components/filter/QXAFNnjuL3'))
const KexnW5g4Aq = React.lazy(() => import('../components/filter/KexnW5g4Aq'))
const NW9KEx21nO = React.lazy(() => import('../components/filter/NW9KEx21nO'))
const FcpFhEPPYk = React.lazy(() => import('../components/filter/FcpFhEPPYk'))

const VVtQlfjWKR = React.lazy(() => import('../components/match/VVtQlfjWKR'))
const OnAITQ9jUk = React.lazy(() => import('../components/match/OnAITQ9jUk'))
const CGLNvlnqxz = React.lazy(() => import('../components/match/CGLNvlnqxz'))
const EJuGbAskro = React.lazy(() => import('../components/match/EJuGbAskro'))
const ZmncKoH1od = React.lazy(() => import('../components/match/ZmncKoH1od'))
const Lyac8yjJww = React.lazy(() => import('../components/match/Lyac8yjJww'))
const CcxtKSX5Es = React.lazy(() => import('../components/match/CcxtKSX5Es'))
const SO9KhhS2Ch = React.lazy(() => import('../components/match/SO9KhhS2Ch'))
const XId1fq8fJX = React.lazy(() => import('../components/match/XId1fq8fJX'))
const FoFZesz5V4 = React.lazy(() => import('../components/match/FoFZesz5V4'))

const Y9QjMkzFDt = React.lazy(() => import('../components/other/Y9QjMkzFDt'))
const XXF2WzP65Y = React.lazy(() => import('../components/other/XXF2WzP65Y'))

export interface ToolItem {
    id: string
    name: string
    isMenu?: boolean
    isGroup?: boolean
    children?: ToolItem[]
    component?: ReactNode
    icon?: ReactNode
}

const items: ToolItem[] = [
    {
        id: 'text',
        name: '文本',
        isMenu: true,
        children: [
            {
                id: 'text-group-1',
                name: '去重',
                isGroup: true,
                children: [
                    {
                        id: 'QkeW5NJt94',
                        name: '文本去重复',
                        component: <QkeW5NJt94 toolId="QkeW5NJt94" toolName="文本去重复" />,
                    },
                    {
                        id: 'NznOHs419G',
                        name: '多功能文本去重',
                        component: <NznOHs419G toolId="NznOHs419G" toolName="多功能文本去重" />
                    },
                ],
            },
            {
                id: 'text-group-2',
                name: '文本行',
                isGroup: true,
                children: [
                    {
                        id: 'TNo9JCtpuy',
                        name: '文本行批量插入字符串',
                        component: <TNo9JCtpuy toolId="TNo9JCtpuy" toolName="文本行批量插入字符串" />
                    },
                    {
                        id: 'WhX2xWx0Pr',
                        name: '文本行批量删除N个字符',
                        component: <WhX2xWx0Pr toolId="WhX2xWx0Pr" toolName="文本行批量删除N个字符" />,
                    },
                    {
                        id: 'AJOBtWw6HC',
                        name: '文本行固定长度填充',
                        component: <AJOBtWw6HC toolId="AJOBtWw6HC" toolName="文本行固定长度填充" />
                    },
                    {
                        id: 'JDiadXgiFX',
                        name: '文本行批量克隆复制',
                        component: <JDiadXgiFX toolId="JDiadXgiFX" toolName="文本行批量克隆复制" />
                    },
                    {
                        id: 'Vi4og4ak30',
                        name: '文本行批量截取',
                        component: <Vi4og4ak30 toolId="Vi4og4ak30" toolName="文本行批量截取" />
                    },
                    {
                        id: 'Z34T6ONYAK',
                        name: '文本行合并',
                        component: <Z34T6ONYAK toolId="Z34T6ONYAK" toolName="文本行合并" />
                    },
                ],
            },
            {
                id: 'text-group-3',
                name: '文本列',
                isGroup: true,
                children: [
                    {
                        id: 'Yzlo6UriFz',
                        name: '文本列合并',
                        component: <Yzlo6UriFz toolId="Yzlo6UriFz" toolName="文本列合并" />
                    },
                    {
                        id: 'KEyJoopqOl',
                        name: '文本列取交集',
                        component: <KEyJoopqOl toolId="KEyJoopqOl" toolName="文本列取交集" />
                    },
                    {
                        id: 'MDhYqCqSWC',
                        name: '文本列取差集',
                        component: <MDhYqCqSWC toolId="MDhYqCqSWC" toolName="文本列取差集" />
                    },
                    {
                        id: 'RqS2XScp8v',
                        name: '文本按列截取',
                        component: <RqS2XScp8v toolId="RqS2XScp8v" toolName="文本按列截取" />
                    },
                ],
            },
            {
                id: 'text-group-4',
                name: '排序',
                isGroup: true,
                children: [
                    {
                        id: 'VAt9az4uX7',
                        name: '文本列表排序',
                        component: <VAt9az4uX7 toolId="VAt9az4uX7" toolName="文本列表排序" />
                    },
                    {
                        id: 'SLlD7ZhH2a',
                        name: '文本列表反转排序',
                        component: <SLlD7ZhH2a toolId="SLlD7ZhH2a" toolName="文本列表反转排序" />
                    },
                    {
                        id: 'M57m7VAZNN',
                        name: '汉字排序',
                        component: <M57m7VAZNN toolId="M57m7VAZNN" toolName="汉字排序" />
                    },
                    {
                        id: 'ELGCyD1ITk',
                        name: '文本行字符串左右反转',
                        component: <ELGCyD1ITk toolId="ELGCyD1ITk" toolName="文本行字符串左右反转" />
                    },
                ]
            }
        ],
    },
    {
        id: 'filter',
        name: '过滤清除',
        isMenu: true,
        children: [
            {
                id: 'M28sMxj7zn',
                name: '过滤文本行所有空行',
                component: <M28sMxj7zn toolId="M28sMxj7zn" toolName="过滤文本行空行" />
            },
            {
                id: 'C9dpQ3nrzy',
                name: '过滤文本行所有换行',
                component: <C9dpQ3nrzy toolId="C9dpQ3nrzy" toolName="过滤文本行所有换行" />
            },
            {
                id: 'DLoOjYdYeg',
                name: '删除文本中空格',
                component: <DLoOjYdYeg toolId="DLoOjYdYeg" toolName="删除文本中空格" />
            },
            {
                id: 'QXAFNnjuL3',
                name: '删除文本中数字',
                component: <QXAFNnjuL3 toolId="QXAFNnjuL3" toolName="删除文本中数字" />
            },
            {
                id: 'KexnW5g4Aq',
                name: '删除文本中字母',
                component: <KexnW5g4Aq toolId="KexnW5g4Aq" toolName="删除文本中字母" />
            },
            {
                id: 'NW9KEx21nO',
                name: '删除文本中汉字',
                component: <NW9KEx21nO toolId="NW9KEx21nO" toolName="删除文本中汉字" />
            },
            {
                id: 'FcpFhEPPYk',
                name: '文本行关键词批量删除',
                component: <FcpFhEPPYk toolId="FcpFhEPPYk" toolName="文本行关键词批量删除" />
            },
        ]
    },
    {
        id: 'random',
        name: '随机',
        isMenu: true,
        children: [
            {
                id: 'TzwLcBPMju',
                name: '随机字符串',
                component: <TzwLcBPMju toolId="TzwLcBPMju" toolName="随机字符串" />
            },
            {
                id: 'ALckybAgOT',
                name: 'UUID',
                component: <ALckybAgOT toolId="ALckybAgOT" toolName="UUID" />
            },
            {
                id: 'IKwWqrV3sg',
                name: '手机号码生成器',
                component: <IKwWqrV3sg toolId="IKwWqrV3sg" toolName="手机号码生成器" />
            },
            {
                id: 'W5AuPlAIqk',
                name: '身份证号码生成器',
                component: <W5AuPlAIqk toolId="W5AuPlAIqk" toolName="身份证号码生成器" />
            },
            {
                id: 'X2PzyXs35H',
                name: '随机IP地址',
                component: <X2PzyXs35H toolId="X2PzyXs35H" toolName="随机IP地址" />
            },
        ]
    },
    {
        id: 'crypto',
        name: '加密解密',
        isMenu: true,
        children: [
            {
                id: 'SoecB2XYuG',
                name: '生成RSA密钥对',
                component: <SoecB2XYuG toolId="SoecB2XYuG" toolName="生成RSA密钥对" />
            },
            {
                id: 'crypto-group-1',
                name: '加密',
                isGroup: true,
                children: [
                    {
                        id: 'FVmHTbUrzL',
                        name: 'MD5加密',
                        component: <FVmHTbUrzL toolId="FVmHTbUrzL" toolName="MD5加密" />
                    },
                    {
                        id: 'RAV8WUXLE4',
                        name: 'SHA加密',
                        component: <RAV8WUXLE4 toolId="RAV8WUXLE4" toolName="SHA加密" />
                    },
                    {
                        id: 'E54UyZIdbe',
                        name: 'AES加密',
                        component: <E54UyZIdbe toolId="E54UyZIdbe" toolName="AES加密" />
                    },
                    {
                        id: 'GNvUgiUaEb',
                        name: 'DES加密',
                        component: <GNvUgiUaEb toolId="GNvUgiUaEb" toolName="DES加密" />
                    },
                    {
                        id: 'UdE42lJsPj',
                        name: 'RSA加密',
                        component: <UdE42lJsPj toolId="UdE42lJsPj" toolName="RSA加密" />
                    },
                ],
            },
            {
                id: 'crypto-group-2',
                name: '解密',
                isGroup: true,
                children: [
                    {
                        id: 'T3VwLebNFv',
                        name: 'AES解密',
                        component: <T3VwLebNFv toolId="T3VwLebNFv" toolName="AES解密" />
                    },
                    {
                        id: 'QyNIFVh5AD',
                        name: 'DES解密',
                        component: <QyNIFVh5AD toolId="QyNIFVh5AD" toolName="DES解密" />
                    },
                    {
                        id: 'PPEhJ3sfUD',
                        name: 'RSA解密',
                        component: <PPEhJ3sfUD toolId="PPEhJ3sfUD" toolName="RSA解密" />
                    },
                ],
            },
        ]
    },
    {
        id: 'match',
        name: '筛选匹配',
        isMenu: true,
        children: [
            {
                id: 'VVtQlfjWKR',
                name: '正则表达式测试',
                component: <VVtQlfjWKR toolId="VVtQlfjWKR" toolName="正则表达式测试" />
            },
            {
                id: 'OnAITQ9jUk',
                name: '文字内容批量替换',
                component: <OnAITQ9jUk toolId="OnAITQ9jUk" toolName="文字内容批量替换" />
            },
            {
                id: 'CGLNvlnqxz',
                name: '筛选包含关键字的文本行',
                component: <CGLNvlnqxz toolId="CGLNvlnqxz" toolName="筛选包含关键字的文本行" />
            },
            {
                id: 'EJuGbAskro',
                name: '筛选不包含关键字的文本行',
                component: <EJuGbAskro toolId="EJuGbAskro" toolName="筛选不包含关键字的文本行" />
            },
            {
                id: 'ZmncKoH1od',
                name: '文本批量筛选奇数行',
                component: <ZmncKoH1od toolId="ZmncKoH1od" toolName="文本批量筛选奇数行" />
            },
            {
                id: 'Lyac8yjJww',
                name: '文本批量筛选偶数行',
                component: <Lyac8yjJww toolId="Lyac8yjJww" toolName="文本批量筛选偶数行" />
            },
            {
                id: 'CcxtKSX5Es',
                name: '文本匹配提取URL链接',
                component: <CcxtKSX5Es toolId="CcxtKSX5Es" toolName="文本匹配提取URL链接" />
            },
            {
                id: 'SO9KhhS2Ch',
                name: '文本匹配提取IP地址',
                component: <SO9KhhS2Ch toolId="SO9KhhS2Ch" toolName="文本匹配提取IP地址" />
            },
            {
                id: 'XId1fq8fJX',
                name: '文本匹配提取邮箱地址',
                component: <XId1fq8fJX toolId="XId1fq8fJX" toolName="文本匹配提取邮箱地址" />
            },
            {
                id: 'FoFZesz5V4',
                name: '文本匹配提取手机号码',
                component: <FoFZesz5V4 toolId="FoFZesz5V4" toolName="文本匹配提取手机号码" />
            },
        ]
    },
    {
        id: 'convert',
        name: '转换',
        isMenu: true,
        children: [
            {
                id: 'convert-group-1',
                name: '进制转换',
                isGroup: true,
                children: [
                ],
            },
            {
                id: 'convert-group-2',
                name: '编码转换',
                isGroup: true,
                children: [
                ],
            },
        ]
    },
    {
        id: 'other',
        name: '其他',
        isMenu: true,
        children: [
            {
                id: 'Y9QjMkzFDt',
                name: '手机号码归属地',
                component: <Y9QjMkzFDt toolId="Y9QjMkzFDt" toolName="手机号码归属地" />
            },
            {
                id: 'XXF2WzP65Y',
                name: '身份证号码解析',
                component: <XXF2WzP65Y toolId="XXF2WzP65Y" toolName="身份证号码解析" />
            },
        ]
    },
]

export default items