import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _pullAllBy from 'lodash/pullAllBy';

export function getToolsCache(key: string): ToolState[] {
    try {
        // 获取本地存储数据
        const localData = localStorage.getItem(key);
        const data = localData ? localData.split('&&') : []
        let tools: ToolState[] = []

        for (let i = 0; i < data.length; i++) {
            tools.push(JSON.parse(data[i]))
        }
        return tools
    } catch (error) {
        return []
    }
}

export function setToolsCache(key: string, data: ToolState[]) {
    let tools: string[] = []

    for (let i = 0; i < data.length; i++) {
        tools.push(JSON.stringify(data[i]))
    }

    // 更新本地存储
    localStorage.setItem(key, tools.join('&&'));
}

export interface ToolState {
    id: string
    name: string
}

// 初始化
const initialState: { value: ToolState[] } = {
    value: getToolsCache('my-common-tools'),
}

export const toolsSlice = createSlice({
    name: 'tools',
    initialState,
    reducers: {
        addCommonTool: (state, action: PayloadAction<ToolState>) => {
            const tool = action.payload
            _pullAllBy(state.value, [{ id: tool.id }], 'id')
            // 在数组头部插入
            state.value.unshift(tool);
            setToolsCache('my-common-tools', state.value)
        },
        removeCommonTool: (state, action: PayloadAction<ToolState>) => {
            const tool = action.payload
            _pullAllBy(state.value, [{ id: tool.id }], 'id')
            setToolsCache('my-common-tools', state.value)
        },
    },
})

export const { addCommonTool, removeCommonTool } = toolsSlice.actions

export default toolsSlice.reducer