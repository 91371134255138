import { Navigate } from "react-router-dom";

import Layout from '../views/pages/Layout';
import Home from '../views/pages/Home';
import Tool from '../views/pages/Tool';

export default [
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: 't/:toolId',
                element: <Tool />,
            },
            {
                path: '*',
                element: <Navigate to='/' />
            }
        ]
    },
]
